/*
 *ajax
 */

//引入
import request from "../utils/request";

// 注册
export const Register = (data) => {
  return request({
    url: "/api/sys/user/register",
    method: "post",
    data,
  });
};

// 图片上传
export const File = (data) => {
  return request({
    url: "/api/file/upload",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};

//签到
export const Sign = (data) => {
  return request({
    url: "/api/sys/sign/autograph",
    method: "post",
    data,
  });
};

//生成二维码
export const QRcode = (data) => {
  return request({
    url: "/api/sys/sign/findByQrCode",
    method: "post",
    data,
  });
};

//登录
export const Login = (data) => {
  return request({
    url: "/api/login",
    method: "post",
    data,
  });
};

export const getOpenId = (code) => {
  return request({
    url: `/api/sys/user/getOpenId/${code}`,
    method: "get",
  });
};

export const getDiploma = (openid) => {
  return request({
    url: `/api/sys/sign/getDiploma/${openid}`,
    method: "get",
  });
};

/**
 * 获取打卡记录
 * @param {} openid
 * @returns
 */
export const getcodeRecode = (data) => {
  return request({
    url: `/api/sys/sign/findByList`,
    method: "post",
    data,
  });
};

/**
 * 保存证书照片
 * @param {} openid
 * @returns
 */
export const updateDiploma = (data) => {
  return request({
    url: `/api/sys/sign/updateDiploma`,
    method: "post",
    data,
  });
};

export const findByZH = (openid) => {
  return request({
    url: `/api/sys/sign/findByZH/${openid}`,
    method: "get",
  });
};

/**
 * 获取用户视频观看列表(审核通过)
 * @param {} openid
 * @returns
 */
export const getVideoByList = (data) => {
  return request({
    url: `/api/sys/video/findByList`,
    method: "post",
    data,
  })
}
/**
 * 获取用户视频观看列表(审核未通过)
 * @param {} openid
 * @returns
 */
export const getVideoList = (data) => {
  return request({
    url: `/api/sys/video/findList`,
    method: "post",
    data,
  })
}

/**
 * 播放完成
 * @param  {} openid
 * @returns
 */
export const videoComplete = (data) => {
  return request({
    url: `/api/sys/video/complete`,
    method: "post",
    data,
  })
}
