<template>
  <div class="app-container">
    <img :src="imgUrl" alt="" />
  </div>
</template>

<script>
import { Notify } from "vant";
import { findByZH } from "@/api/login";

export default {
  data() {
    return {
      imgUrl: require("../assets/img/bg.png"),
    };
  },
  mounted() {
    let openId = location.href.split("?")[1].split("=")[1];
    if (openId) {
      this.getCert(openId);
    } else {
      Notify({ type: "danger", message: "非法进入页面,请通过扫码识别证书" });
    }
  },
  methods: {
    getCert(openid) {
      findByZH(openid).then((res) => {
        console.log(res, 11111);
        if (res.data.code == 200) {
          this.imgUrl = res.data.data.diploma;
        } else {
          Notify({ type: "danger", message: res.data.msg });
        }
      });
    },
  },
  created() {},
  watch: {},
};
</script>

<style lang="less" scoped>
.app-container {
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  img {
    width: 100%;
  }
}
</style>
