<template>
  <div class="login">
    <!--<van-nav-bar
      title="用户注册"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />-->

    <!-- 信息完善 -->
    <div class="information">
      <div class="title">信息完善</div>
      <van-form @failed="onFailed" ref="formRule">
        <van-cell-group inset>
          <div class="identity">
            <div class="my"><span>*</span> 我是</div>
            <van-field
              v-model="form.roleCode"
              is-link
              name="picker"
              readonly
              @click="show = true"
              placeholder="请选择角色"
            />
            <van-popup v-model="show" position="bottom">
              <van-picker
                :columns="actions"
                @confirm="onSelect"
                @cancel="show = false"
                :show-toolbar="true"
              />
            </van-popup>
            <!-- <van-action-sheet
            v-model="show"
            :actions="actions"
            @select="onSelect"
          /> -->
          </div>

          <div class="identity">
            <div class="my"><span>*</span> 参会地</div>
            <van-field
              is-link
              readonly
              name="picker"
              :value="form.city"
              placeholder="请选择省市区"
              @click="showArea = true"
            />
            <van-popup v-model="showArea" position="bottom">
              <van-picker
                :columns="columns"
                @confirm="onConfirm"
                @cancel="onCancel"
                @change="onChange"
                :show-toolbar="true"
              />
            </van-popup>
          </div>
          <div class="identity" v-if="form.roleCode == '业主代表'">
            <div class="my"><span>*</span> 小区名称</div>
            <van-field v-model="form.houseName" placeholder="请输入小区名称" />
          </div>
          <div class="identity">
            <div class="my">
              <span v-if="form.roleCode != '业主代表'">*</span>
              <span v-else> </span> 从业单位
            </div>
            <van-field v-model="form.company" placeholder="请输入从业单位" />
          </div>
          <div class="identity">
            <div class="my"><span>*</span> 姓名</div>
            <van-field v-model="form.name" placeholder="请输入姓名" />
          </div>
          <div class="identity">
            <div class="my">
              <span>*</span> 联系电话<span class="phoneInfo"
                >(已绑定本手机微信)</span
              >
            </div>
            <van-field v-model="form.phone" placeholder="请输入联系电话" />
          </div>
          <div class="identity">
            <div class="my"><span>*</span> 身份证号</div>
            <van-field v-model="form.idCardCode" placeholder="请输入身份证号" />
          </div>
          <div class="identity" v-if="form.roleCode != '业主代表'">
            <div class="my"><span>*</span> 职务</div>
            <van-field v-model="form.post" placeholder="请输入职务" />
          </div>
          <div class="identity" v-if="form.roleCode != '业主代表'">
            <div class="my"><span>*</span> 统一社会信用代码</div>
            <van-field v-model="form.credit" placeholder="请输入社会信用代码" />
          </div>
          <!-- 注册 -->
          <div>
            <van-button round type="info" size="large" @click="registe"
              >注册</van-button
            >
          </div>
        </van-cell-group>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast, Notify } from "vant";
import { areaList } from "@vant/area-data";
import { Register, File } from "@/api/login";
export default {
  data() {
    return {
      //用户注册列表
      form: {
        roleCode: "",
        state: "",
        company: "",
        name: "",
        phone: "",
        idCardCode: "",
        post: "",
        credit: "",
        city: "",
      },
      columns: [
        "成都市",
        "南充市",
        "广元市",
        "巴中市",
        "广安市",
        "眉山市",
        "遂宁市",
        "达州市",
        "绵阳市",
        "资阳市",
        "德阳市",
        "内江市",
        "乐山市",
        "宜宾市",
        "泸州市",
        "凉山彝族自治州",
        "自贡市",
        "攀枝花市",
        "甘孜藏族自治州",
        "阿坝藏族羌族自治州",
        "雅安市",
      ],
      params: {},
      fileList: [],
      files: "",
      file: "",
      name: "",
      creditPattern: /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/,
      itm: "",
      show: false,
      actions: ["物业企业", "主管部门", "协会", "业主代表"],
      value: "",
      showArea: false,
      areaList,
    };
  },
  watch: {
    form(newName, oldName) {
      this.name = newName;
      console.log(newName, 555);
    },
  },

  methods: {
    //身份下拉框
    onSelect(val) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.form.roleCode = val;
    },
    //头部返回按钮
    onClickLeft() {
      window.history.back();
    },
    onClickRight() {},
    //省份选择
    onConfirm(values) {
      this.form.city = values;
      this.showArea = false;
    },
    onCancel() {
      this.showArea = false;
    },
    onChange() {},
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },

    //发送ajax注册
    async registe() {
      if (this.form.roleCode == "") {
        Notify({ type: "danger", message: "请先选择您的角色" });
        return false;
      }
      if (this.form.city == "") {
        Notify({ type: "danger", message: "请先选择您的参会地" });
        return false;
      }
      if (this.form.roleCode == "业主代表" && this.form.houseName == "") {
        Notify({ type: "danger", message: "请输入您的小区名称" });
        return false;
      }

      if (this.form.roleCode != "业主代表" && this.form.company == "") {
        Notify({ type: "danger", message: "请输入您的从业单位" });
        return false;
      }
      if (this.form.name == "") {
        Notify({ type: "danger", message: "请先选择您的姓名" });
        return false;
      }
      if (this.form.phone == "" || !/^1[3-9]\d{9}$/.test(this.form.phone)) {
        Notify({ type: "danger", message: "请先输入正确的手机号" });
        return false;
      }
      if (
        this.form.idCardCode == "" ||
        !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          this.form.idCardCode
        )
      ) {
        Notify({ type: "danger", message: "请先输入正确的身份证号" });
        return false;
      }

      if (this.form.roleCode != "业主代表" && this.form.post == "") {
        Notify({ type: "danger", message: "请先输入您的职务" });
        return false;
      }
      if (
        this.form.roleCode != "业主代表" &&
        (this.form.credit == "" ||
          !/[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/.test(
            this.form.credit
          ))
      ) {
        Notify({ type: "danger", message: "请先输入正确的统一社会信用代码" });
        return false;
      }

      let username = this.form.phone;
      let nickname = this.form.name;
      let companyCode = this.form.credit;
      let companyName = this.form.company;
      let position = this.form.post;
      let city = this.form.city;
      let roleCode = this.form.roleCode;
      let idCardCode = this.form.idCardCode;
      let houseName = this.form.houseName;
      this.params = {
        username: username,
        nickname: nickname,
        companyCode: companyCode,
        companyName: companyName,
        position: position,
        city: city,
        roleCode: roleCode,
        idCardCode: idCardCode,
        houseName: houseName,
        // businessImg: businessImg,
      };
      this.params.openId = sessionStorage.getItem("openId");
      Register(this.params).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$router.push({
            path: "/home",
          });
        }
      });
    },
  },
  watch: {
    Identity(newName, oldName) {
      this.form.identity = newName + " " + this.lastName;
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  overflow: scroll;
  height: 100%;
  margin-bottom: 30px;
}
.information {
  padding: 24px;
  box-sizing: border-box;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #1b1b1b;
    margin-bottom: 35px;
  }
  .identity {
    margin-bottom: 15px;
    .my {
      font-size: 16px;
      color: #444444;
      span {
        color: #ff4343;
        &.phoneInfo {
          color: #0a84ff;
        }
      }
    }

    .van-cell__title {
      span {
        font-weight: 500;
        font-size: 16px;
        color: #a7abb2;
      }
    }
    /deep/.van-field__control::-webkit-input-placeholder {
      font-size: 16px;
    }
  }
}
/deep/.van-cell-group {
  border-top: none;
}
.license {
  margin-bottom: 20px;
  position: relative;
  .my {
    font-size: 12px;
    color: #444444;
    margin-bottom: 15px;
    span {
      color: #ff4343;
    }
  }

  .van-cell__title {
    span {
      font-weight: 500;
      font-size: 16px;
      color: #a7abb2;
    }
  }
  .van-icon-arrow {
    position: absolute;
    top: 28px;
    color: #969799;
  }

  .support {
    font-weight: 500;
    color: #828282;
    font-size: 12px;
  }
}
.acat {
  width: 100px;
  height: 100px;
  background-color: #f2f3fb;
  border: 1px solid #b5d1ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .van-button--primary {
    border: none;
    background-color: #f2f3fb;
    .van-icon {
      color: #b5d1ff;
      font-size: 40px;
    }
  }
  .upload {
    color: #b5d1ff;
    margin-top: 15px;
    font-weight: 500;
    font-size: 12px;
  }
}
/deep/.van-uploader__preview-image {
  width: 100px !important;
  height: 100px !important;
}
/deep/.van-hairline--top-bottom::after {
  border-top: none;
}
</style>
