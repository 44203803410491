import Vue from "vue";
import VueRouter from "vue-router";

import Clock from "../views/clock/clock.vue";
import Person from "../views/person/person.vue";
import Video from "../views/video/video.vue";
import Myvideo from "../views/video/myvideo.vue";
import Login from "../views/login/login.vue";
import Home from "../views/home.vue";
import LginIn from "../views/lginIn.vue";
import QrCode from "../views/QrCode.vue";
import certificate from "../views/certificate.vue";

Vue.use(VueRouter);

// 重写路由的push 解决跳转当前导航的警告
const originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originPush.call(this, location).catch((err) => err);
};

const routes = [
  //如果为/直接跳转到打卡
  { path: "/", redirect: "/video" },
  //登陆
  {
    path: "/LginIn",
    name: "LginIn",
    component: LginIn,
  },
  //注册
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  //签到
  {
    path: "/home",
    name: "Home",
    component: Clock,
    children: [{ path: "/home", component: Home,meta:{
      title:'签到'
      } }],
  },

  //个人
  {
    path: "/person",
    name: "Person",
    component: Clock,
    children: [{ path: "/person", component: Person,meta:{
        title:'个人'
      } }],
  },
  //二维码
  {
    path: "/QrCode",
    name: "/QrCode",
    component: QrCode,
    meta:{
      title:'二维码'
    }
  },
  //查看证书页面
  {
    path: "/certificate",
    name: "/certificate",
    component: certificate,
    meta:{
      title:'证书预览'
    }
  },
  //视频
  {
    path: "/video",
    name: "Video",
    component: Clock,
    children: [{ path: "/video", component: Video ,meta:{
        title:'视频'
      }}],
  },
  //播放视频
  {
    path: "/myvideo",
    name: "Myvideo",
    component: Clock,
    children: [{ path: "/myvideo", component: Myvideo ,meta:{
        title:'视频详情'
      }}],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
