<template>
  <div class="home">
    <!-- <van-nav-bar
      title=""
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />-->
    <!-- 头部插画 -->
    <img src="../assets/img/Group 128.png" width="100%" height="175px" alt="" />

    <!-- 打卡信息栏 -->
    <div class="box">
      <div class="name">{{ information.nickname }}</div>
      <div class="text">{{ information.companyName }}</div>
      <div class="times">
        <div class="time">
          <div class="mor">{{ codeList.startTime }}</div>
          <div
            :class="lateOne ? 'sure late' : 'sure'"
            v-if="codeList.startSignTime"
          >
            {{ lateOne ? "迟到打卡" : "打卡时间" }}：{{
              codeList.startSignTime
            }}
          </div>

          <div class="sure" v-else>
            <!-- {{ codeList.startSignStu == "-1" ? "缺卡" : "未打卡" }} -->
            未打卡
          </div>
          <van-icon
            name="checked"
            v-if="codeList.startSignTime"
            color="#25C28A"
          />
          <van-icon name="clear" v-else color="red" />
        </div>

        <div class="time">
          <div class="mor">{{ codeList.noonTime }}</div>
          <div class="sure" v-if="codeList.noonSignTime">
            打卡时间：{{ codeList.noonSignTime }}
          </div>

          <div class="sure" v-else>
            <!-- {{ codeList.noonSignStu == "-1" ? "缺卡" : "未打卡" }} -->
            未打卡
          </div>
          <van-icon
            name="checked"
            v-if="codeList.noonSignTime"
            color="#25C28A"
          />
          <van-icon name="clear" v-else color="red" />
        </div>

        <div class="time">
          <div class="mor">{{ codeList.afterTime }}</div>
          <div
            :class="lateThree ? 'sure late' : 'sure'"
            v-if="codeList.afterSignTime"
          >
            {{ lateThree ? "迟到打卡" : "打卡时间" }}：{{
              codeList.afterSignTime
            }}
          </div>
          <div class="sure" v-else>
            <!-- {{ codeList.afterSignStu == "-1" ? "缺卡" : "未打卡" }} -->
            未打卡
          </div>
          <van-icon
            name="checked"
            v-if="codeList.afterSignTime"
            color="#25C28A"
          />
          <van-icon name="clear" v-else color="red" />
        </div>

        <div class="time">
          <div class="mor">{{ codeList.endTime }}</div>
          <div class="sure" v-if="codeList.endSignTime">
            打卡时间：{{ codeList.endSignTime }}
          </div>
          <div class="sure" v-else>
            <!-- {{ codeList.endSignStu == "-1" ? "缺卡" : "未打卡" }} -->
            未打卡
          </div>
          <van-icon
            name="checked"
            v-if="codeList.endSignTime"
            color="#25C28A"
          />
          <van-icon name="clear" v-else color="red" />
        </div>
      </div>
    </div>

    <!-- 扫码打卡 -->
    <div class="clock" v-if="!isSuccess">
      <button class="button" @click="clock">
        <van-icon name="scan" /><span>扫码签到</span>
      </button>

      <Scaner
        v-if="scanerFlag"
        v-on:code-scanned="codeScanned"
        v-on:error-captured="errorCaptured"
        :stop-on-scanned="true"
        :draw-on-found="true"
        :responsive="false"
      />
    </div>
    <div class="clockSuccess" v-else>
      <img src="../assets/img/img_daka_gray.png" alt="" />
      <p>打卡已完成</p>
    </div>

    <!-- 弹出框 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div :class="toastType ? 'block success' : 'block error'">
          <div class="success">
            <div class="succ">{{ toastType ? "打卡成功" : "打卡失败" }}</div>
            <div class="sec">{{ toastMsg }}</div>
          </div>
          <van-button type="default" @click="show = false">好的</van-button>
        </div>
      </div>
    </van-overlay>

    <!-- 定义地图显示容器 -->
    <div id="city" class="map-positon"></div>
  </div>
</template>

<script>
import { Toast, Notify, Dialog } from "vant";
import { Sign, Login, QRcode, getOpenId, getcodeRecode } from "@/api/login";
import maps from "qqmap";
import { getYMDHMS } from "@/utils/date";
import Scaner from "../components/Scaner.vue";
import { getOpenid, information } from "@/utils/util";

export default {
  name: "Scan",
  components: {
    Scaner,
  },
  data() {
    return {
      errorMessage: "",
      scanned: "",
      show: false,
      login: { openId: "" },
      information: {},
      scanerFlag: false,
      dataList: [],
      formData: {
        address: "",
      },
      codename: "上午签到",
      dialogFlag: false,
      lat: "",
      lng: "",
      year: "",
      params: {},
      url: "",
      codeList: {
        startTime: "",
      },
      // types: 1,
      lateOne: false,
      lateThree: false,
      toastMsg: "1111",
      isSuccess: false, // 是否打卡完成
      toastType: false,
    };
  },
  created() {
    this.information = information();
    this.login.openId = getOpenid();
  },
  mounted() {
    /**
     * 初始化地图 获取定位
     */
    // this.initCityService();
    this.initGeolocation();
    /**初始化打卡信息**/
    this.getCodeRecode();
  },
  methods: {
    //返回
    onClickLeft() {
      window.history.back();
    },
    onClickRight() {
      Toast("按钮");
    },
    //点击扫码
    clock() {
      this.scanerFlag = true;
      var str = navigator.userAgent.toLowerCase();
      var ver = str.match(/cpu iphone os (.*?) like mac os/);
      if (ver && ver[1].replace(/_/g, ".") < "10.3.3") {
        Notify({ type: "danger", message: "调用摄像头失败" });
      }
    },
    initCityService() {
      var _this = this;
      var city = document.getElementById("city");
      //调用城市服务信息
      this.citylocation = new qq.maps.CityService({
        complete: function (res) {
          // 在pc测试时，会有经纬度负数的情况.....我没找原因=。=，直接处理了下
          // if (!res.detail.name) {
          //   return;
          // } else {
          //   _this.currentCity_detail = res.detail.detail;
          //   _this.currentCity = res.detail.name;
          // }
        },
      });
      // setError 设置检索失败后的回调函数。
      this.citylocation.setError(function () {
        alert("出错了，没有获得正确的经纬度！！！");
      });
    },
    /**
     * 腾讯地图获取当前位置
     */
    initGeolocation() {
      var geolocation = new qq.maps.Geolocation(
        "FNBBZ-XALCO-7OQWE-SPXMF-WQXJS-TJBGV",
        "myapp"
      );
      geolocation.watchPosition((position) => {
        // this.initMap(position.lat, position.lng);
        this.geolocation_latlng(position.lat, position.lng);
      });
    },
    geolocation_latlng(lat, lng) {
      this.lat = lat;
      this.lng = lng;
      if (lat != 0 && lng != 0) {
        var latLng = new qq.maps.LatLng(lat, lng);
        // this.initMap(lat, lng);
        this.citylocation.searchCityByLatLng(latLng);
      }
    },
    /**
     * 扫码打卡
     */
    async codeScanned(code) {
      let currentCode = JSON.parse(code);
      this.params = {
        openId: this.login.openId,
        latitude: this.lat,
        longitude: this.lng,
        sign_latitude: currentCode.sign_latitude,
        sign_longitude: currentCode.sign_longitude,
        pid: currentCode.pid,
        // types: this.types,
      };
      await Sign(this.params).then((res) => {
        this.show = true;
        if (res.data.code == 200) {
          // Notify({ type: "success", message: "签到成功" });
          this.toastMsg = "打卡成功";
          this.toastType = true;
          this.getCodeRecode();
        } else {
          this.toastType = false;
          this.toastMsg = res.data.msg;
        }
        this.scanerFlag = false;
      });
    },
    errorCaptured(error) {
      switch (error.name) {
        case "NotAllowedError":
          this.errorMessage = "Camera permission denied.";
          break;
        case "NotFoundError":
          this.errorMessage = "There is no connected camera.";
          break;
        case "NotSupportedError":
          this.errorMessage =
            "Seems like this page is served in non-secure context.";
          break;
        case "NotReadableError":
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?";
          break;
        case "OverconstrainedError":
          this.errorMessage = "Constraints don't match any installed camera.";
          break;
        default:
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
      }
      Notify({ type: "danger", message: this.errorMessage });
    },
    /**
     * 获取openid
     */
    getOpenidFnc(code) {
      getOpenId(code).then((res) => {
        if (res.data.code == 200) {
          this.login.openId = res.data.data.openid;
          sessionStorage.setItem("openId", res.data.data.openid);
          this.loginFnc();
        } else {
          Notify({ type: "danger", message: "获取用户id失败" });
        }
      });
    },
    /**
     * 获取打卡记录
     */
    getCodeRecode() {
      let obj = {
        openId: this.login.openId,
      };
      getcodeRecode(obj).then((res) => {
        if (res.data.code == 200) {
          this.codeList.afterSignTime = this.formatDate(
            res.data.data.afterSignTime
          );
          this.codeList.startSignStu = res.data.data.startSignStu;
          this.codeList.noonSignStu = res.data.data.noonSignStu;
          this.codeList.afterSignStu = res.data.data.afterSignStu;
          this.codeList.endSignStu = res.data.data.endSignStu;

          this.codeList.afterTime = this.formatDate(res.data.data.afterTime);

          this.codeList.endSignTime = this.formatDate(
            res.data.data.endSignTime
          );
          this.codeList.endTime = this.formatDate(res.data.data.endTime);
          this.codeList.noonSignTime = this.formatDate(
            res.data.data.noonSignTime
          );
          this.codeList.noonTime = this.formatDate(res.data.data.noonTime);
          this.codeList.startSignTime = this.formatDate(
            res.data.data.startSignTime
          );
          this.codeList.startTime = this.formatDate(res.data.data.startTime);
          this.codeList = JSON.parse(JSON.stringify(this.codeList));
          // 第一次打卡是否迟到
          if (
            this.codeList.startSignTime &&
            this.codeList.startTime < this.codeList.startSignTime
          ) {
            this.lateOne = true;
          } else {
            this.lateOne = false;
          }

          // 第三次打卡是否迟到
          if (
            this.codeList.afterSignTime &&
            this.codeList.afterTime < this.codeList.afterSignTime
          ) {
            this.lateThree = true;
          } else {
            this.lateThree = false;
          }

          if (this.codeList.endSignTime != "") {
            this.isSuccess = true;
          }
        }
      });
    },
    /**
     * login
     */
    loginFnc() {
      let that = this;
      Login(that.login)
        .then((res) => {
          sessionStorage.setItem("openId", that.login.openId);
          if (res.data.msg == "NoRegister") {
            Dialog.alert({
              message: "当前账号未注册信息，即将前往注册",
            }).then(() => {
              // on close
              that.$router.push({
                path: "/login",
              });
            });
          } else {
            if (res.data.code == 200) {
              sessionStorage.setItem("token", res.data.data.Authorization);
              this.information = res.data.data.principal;
              let obj = JSON.stringify(this.information);
              sessionStorage.setItem("msg", obj);
              this.getCodeRecode();
            }
          }
        })
        .catch((err) => {
          Notify({ type: "danger", message: "服务器异常，登录失败" });
        });
    },
    getUserCode() {
      // const AppId = "wxb233756efac0b769";
      var url = location.href;
      let start = location.href.indexOf("=");
      let end = location.href.indexOf("&");
      let code = url.substring(start + 1, end);
      this.getOpenidFnc(code);
    },

    formatDate(time) {
      let str = "";
      if (time) {
        let currentTime = new Date(time);
        str =
          currentTime.getHours() +
          ":" +
          (currentTime.getMinutes() < 10
            ? "0" + currentTime.getMinutes()
            : currentTime.getMinutes());
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
.scan {
  height: 100%;
  width: 100%;
}
.scan .nav {
  width: 100%;
  height: 48px;
  line-height: 48px;
  position: fixed;
  top: 0;
  left: 0;
}
.scan .nav .title {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #ffffff;
}
.scan .nav .close {
  display: inline-block;
  height: 22px;
  width: 22px;
  background-size: auto 100%;
  position: absolute;
  left: 16px;
  top: 14px;
}
.van-image-preview__cover {
  bottom: 20% !important;
  left: 44% !important;
}
* {
  margin: 0;
  padding: 0;
}
.div {
  position: absolute;
  top: 400px;
  height: 300px;
  width: 300px;
}
.home {
  position: relative;
  height: 100%;
}
.box {
  width: 85%;
  height: 224px;
  position: absolute;
  top: 100px;
  left: 7.5%;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0px 6px 20px 1px rgba(193, 193, 193, 0.2);
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  .name {
    font-size: 20px;
    color: #333;
    font-weight: bold;
  }
  .text {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .times {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .time {
      border: 1px solid #f5f5f5;
      position: relative;
      border-radius: 4px;
      width: 48%;
      margin: 0 1%;
      padding: 8px;
      margin-top: 5px;
      box-sizing: border-box;

      .mor {
        color: #333;
        font-size: 14px;
        font-weight: bold;
      }
      .sure {
        margin-top: 4px;
        color: #828282;
        font-size: 12px;
        font-weight: 500;
        &.late {
          color: red;
        }
      }
    }
    .van-icon {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}
.clock {
  position: absolute;
  text-align: center;
  bottom: -400px;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  height: 200px;
  border: 3px solid #ecf5ff;
  border-radius: 95px;
  .button {
    margin-top: 24px;
    width: 150px;
    height: 150px;
    border-radius: 75px;
    background-color: #559df9;
    border: none;
    color: #fff;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    .van-icon-scan {
      position: absolute;
      font-size: 36px;
      top: 25px;
      left: 55px;
      margin-bottom: 15px;
    }
    span {
      margin-top: 35px;
      display: inline-block;
    }

    input[node-type="jsbridge"] {
      display: none;
    }
    input[class="button"] {
      margin-top: 18px;
      width: 150px;
      height: 150px;
      border-radius: 75px;
      background-color: #559df9;
      border: none;
      color: rgba(0, 0, 0, 0);
      position: relative;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.clockSuccess {
  position: absolute;
  text-align: center;
  bottom: -400px;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  height: 200px;
  img {
    width: 100%;
  }
  p {
    text-align: center;
    padding: 0 5px;
    font-size: 18px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: bold;
    transform: translate(-50%, -50%);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    position: relative;
    width: 250px;
    height: 300px;
    text-align: center;
    &.success {
      background: url("../assets/img/Group 156 (1).png") 100% 100%;
    }
    &.error {
      background: url("../assets/img/Group 156 (2).png") 100% 100%;
    }
    button {
      margin-top: 22px;
      width: 160px;
      height: 48px;
      border-radius: 72px;
      color: #4386f5;
      font-size: 16px;
      font-weight: 500;
    }
    .success {
      margin-top: 135px;
      text-align: center;
      .succ {
        color: #fff;
        font-size: 24px;
        font-weight: 800;
      }
      .sec {
        color: #bbd9fe;
        font-size: 14px;
        font-weight: 500;
        margin-top: 4px;
      }
    }
  }
}
</style>
