<template>
  <div class="lginIn">
    <div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="username"
          name="username"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div class="div" style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Login } from "@/api/login";
export default {
  data() {
    return {
      username: "",
      password: "",
      types: "6",
    };
  },
  methods: {
    onSubmit(values) {
      let obj = {
        username: values.username,
        password: values.password,
        types: this.types,
      };

      Login(obj).then((res) => {
        console.log(res, 22222);
        if (res.data.code == 200) {
          sessionStorage.setItem(
            "Authorization",
            JSON.stringify(res.data.data.Authorization)
          );
          this.$router.push("/home");
          window.location.reload();
        }
        console.log(res);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.lginIn {
  margin: 15% auto;

  /deep/.van-cell {
    width: 25% !important;
    margin: 0px auto;
  }
  .div {
    margin: 16px 37% !important;
  }
  .van-button--block {
    width: 100%;
  }
}
</style>
