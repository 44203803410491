<template>
  <div class="qrcode">
    <div class="code">
      <div> <img :src="url"
             alt=""
             width="100px"
             height="100px"></div>
      <div> <button @click="btn"> 生成二维码</button></div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { QRcode } from "@/api/login"
import maps from 'qqmap'
import { getYMDHMS } from '@/utils/date'
export default {

  data () {
    return {
      login: { "openId": "123456gla" },
      information: {},
      s: false,
      dataList: [],
      formData: {
        address: ""
      },
      dialogFlag: false,
      lat: '',
      lng: '',
      year: '',
      params: {},
      url: '',
    }
  },
  methods: {
    btn () {
      this.initCityService();
      this.initGeolocation();
      this.year = getYMDHMS()
      this.params = { "sign_latitude": this.lat, "sign_longitude": this.lng, "trainStartTime": this.year, "trainEndTime": this.year }
      QRcode(this.params).then((res) => {
        if (res.data.code == 200) {
          this.url = res.data.data
        }
      })
    },
    initCityService () {
      var _this = this;
      var city = document.getElementById("city");
      //调用城市服务信息
      this.citylocation = new qq.maps.CityService({
        complete: function (res) {
          // 在pc测试时，会有经纬度负数的情况.....我没找原因=。=，直接处理了下
          // if (!res.detail.name) {
          //   return;
          // } else {
          //   _this.currentCity_detail = res.detail.detail;
          //   _this.currentCity = res.detail.name;
          // }
        }
      });
      // setError 设置检索失败后的回调函数。
      this.citylocation.setError(function () {
        alert("出错了，没有获得正确的经纬度！！！");
      });
    },
    /**
     * 腾讯地图获取当前位置
     */
    initGeolocation () {
      var geolocation = new qq.maps.Geolocation(
        "FNBBZ-XALCO-7OQWE-SPXMF-WQXJS-TJBGV",
        "myapp"
      );
      geolocation.watchPosition(position => {
        // this.initMap(position.lat, position.lng);
        this.geolocation_latlng(position.lat, position.lng);
      });
    },
    geolocation_latlng (lat, lng) {
      this.lat = lat
      this.lng = lng
      console.log(lat);
      console.log(lng);

      if (lat != 0 && lng != 0) {
        var latLng = new qq.maps.LatLng(lat, lng);
        // this.initMap(lat, lng);
        this.citylocation.searchCityByLatLng(latLng);
      }
    },
    codeScanned (code) {
      this.scanned = code;
      setTimeout(() => {
        alert(`扫码解析成功: ${code}`);
      }, 200)
    },
    errorCaptured (error) {
      switch (error.name) {
        case "NotAllowedError":
          this.errorMessage = "Camera permission denied.";
          break;
        case "NotFoundError":
          this.errorMessage = "There is no connected camera.";
          break;
        case "NotSupportedError":
          this.errorMessage =
            "Seems like this page is served in non-secure context.";
          break;
        case "NotReadableError":
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?";
          break;
        case "OverconstrainedError":
          this.errorMessage = "Constraints don't match any installed camera.";
          break;
        default:
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
      }
      console.error(this.errorMessage);
      alert('相机调用失败');
    }
  },
  mounted () {

    this.initCityService();
    this.initGeolocation();

  },
}
</script>

<style lang="less" scoped>
.qrcode {
  width: 100%;
  height: 100%;
  .code {
    margin: 0 auto;
  }
}
</style>