<template>
  <div class="person">
    <!--<van-nav-bar
      title=""
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />-->
    <!-- 头部 -->
    <div class="header">
      <div class="introduce">
        <!-- 头像 -->
        <img :src="url" class="avatar" alt="" />
        <div class="title">
          <div class="name">{{ information.nickname }}</div>
          <div class="company">{{ information.companyName }}</div>
        </div>
      </div>
    </div>

    <!-- 信息栏 -->
    <div class="information">
      <van-tabs
        v-model="activeName"
        @click="clickTab"
        title-active-color="#4386F5"
      >
        <van-tab title="我的信息" name="a">
          <div class="informationitem">
            <div class="from">
              <span class="fotab">身份</span>
              <span class="tab">{{ information.roleCode }}</span>
            </div>
          </div>

          <div class="informationitem">
            <div class="from">
              <span class="fotab">参会地</span>
              <span class="tab">{{ information.city }}</span>
            </div>
          </div>
          <div class="informationitem">
            <div class="from">
              <span class="fotab">单位名称</span>
              <span class="tab">{{ information.companyName }}</span>
            </div>
          </div>

          <div class="informationitem">
            <div class="from">
              <span class="fotab">姓名</span>
              <span class="tab">{{ information.nickname }}</span>
            </div>
          </div>

          <div class="informationitem">
            <div class="from">
              <span class="fotab">电话</span>
              <span class="tab">{{ information.username }}</span>
            </div>
          </div>
          <div class="iphone">
            <p><span>咨询电话1 :</span> 028-85353498</p>
          </div>
          <div class="iphone" style="border-top: none">
            <p><span>咨询电话2 :</span> 028-85350369</p>
          </div>
          <!--          <div class="telphoneCall">-->
          <!--            <a :href="'tel:028-85353498'">-->
          <!--              <img src="../../assets/img/call.png" alt="" />-->
          <!--              <p>咨询</p>-->
          <!--            </a>-->
          <!--          </div>-->
        </van-tab>
        <van-tab title="|" disabled></van-tab>
        <van-tab title="我的证书" name="b">
          <div class="isExcepot" v-if="isExcepot && isAudit">
            <div class="hasdiploma" v-if="hasdiploma">
              <canvas
                id="canvasID"
                v-show="false"
                width="325"
                height="464"
                style="margin: 0 auto"
              ></canvas>
              <img :src="diplomaUrl" alt="" />
              <div
                v-if="isRemake"
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  margin-top: 20px;
                "
              >
                <van-button round style="width: 80px" @click="remake"
                  >重拍</van-button
                >
                <van-button
                  round
                  type="info"
                  style="width: 80px; margin-left: 20px"
                  @click="drawdiploma(true, newUrl)"
                  >确认</van-button
                >
              </div>
            </div>
            <div v-else>
              <van-uploader
                v-model="fileList"
                :after-read="afterRead(itm)"
                :max-count="1"
                :max-size="maxszie"
                @oversize="oversizeimg"
              >
                <div class="acat">
                  <van-button icon="plus" type="primary"></van-button>
                  <div class="upload">上传1寸证件照</div>
                </div>
              </van-uploader>
              <div class="certificate">暂未生成证书</div>
            </div>
          </div>
          <div v-else class="noExcepot">
            <img
              style="height: 120px; width: 120px"
              src="../../assets/img/Maskgroup.png"
              alt=""
            />
            <p>待审核中，不可拍摄头像</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { File, getDiploma, updateDiploma } from "@/api/login";
import { getOpenid, information } from "@/utils/util";

export default {
  components: {},
  data() {
    return {
      itm: "",
      activeName: "a",
      fileList: [],
      information: {},
      isExcepot: false,
      isAudit: false,
      hasdiploma: false,
      diplomaUrl: "",
      qrcode: "",
      url: require("../../assets/img/img_user.png"),
      userUrl: "",
      pid: "",
      bgImg: require("../../assets/img/bg.png"),
      maxszie: 4194304,
      newUrl: "",
      isRemake: false,
    };
  },

  created() {
    this.information = information();
    if (this.$route.query.active) {
      this.activeName = "b";
      this.clickTab();
    }
  },
  methods: {
    oversizeimg() {
      Toast("上传文件过大，请上传小于4M的头像");
    },
    clickTab() {
      this.remake();
      if (this.activeName == "b") {
        let openid = getOpenid();
        getDiploma(openid).then((res) => {
          if (res.data.code == 200) {
            this.isExcepot = res.data.data.status == 1 ? true : false; //status: 1可以开启，2不能开启
            this.isAudit = res.data.data.userStatus == 2 ? true : false; //userStatus状态值: 未审核(1) 审核通过(2);
            this.hasdiploma = res.data.data.diploma ? true : false;
            this.diplomaUrl = res.data.data.diploma;
            this.pid = res.data.data.pid;
            // this.userUrl =
            //   "https://signin.sccqhome.cn/api/file/show/" +
            //   res.data.data.hasdiploma;
            this.qrcode =
              "https://signin.sccqhome.cn/api/file/show/" +
              res.data.data.qrCode;
          }
        });
      }
    },
    getCurrentCode() {},
    onClickLeft() {
      window.history.back();
    },
    onClickRight() {
      Toast("按钮");
    },
    //使用canvas实现文本内容自动换行
    /**
     ctx： 画布的上下文环境
     text： 需要绘制的文本内容
     x： 绘制文本的x坐标
     y： 绘制文本的y坐标
     lineHeight：文本之间的行高
     maxWidth：每行文本的最大宽度
     * **/
    wrapText(ctx, text, x, y, maxWidth, lineHeight) {
      if (
        typeof text != "string" ||
        typeof x != "number" ||
        typeof y != "number"
      ) {
        return;
      }
      var context = ctx;
      var canvas = context.canvas;
      if (typeof maxWidth == "undefined") {
        maxWidth = (canvas && canvas.width) || 300;
      }
      if (typeof lineHeight == "undefined") {
        lineHeight =
          (canvas && parseInt(window.getComputedStyle(canvas).lineHeight)) ||
          parseInt(window.getComputedStyle(document.body).lineHeight);
      }
      // 字符分隔为数组
      var arrText = text.split("");
      var line = "";
      for (var n = 0; n < arrText.length; n++) {
        var testLine = line + arrText[n];
        var metrics = context.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y);
          line = arrText[n];
          y += lineHeight;
        } else {
          line = testLine;
        }
      }
      context.fillText(line, x, y);
    },
    drawdiploma(type, url) {
      let that = this;
      that.hasdiploma = true;
      that.isRemake = true;
      that.userUrl = "https://signin.sccqhome.cn/api/file/show/" + url;
      that.$nextTick(() => {
        Toast.loading({
          message: "生成证书中...",
          type: "loading",
          forbidClick: true,
          duration: 0,
        });
        var canvas = document.getElementById("canvasID");
        let ctx = canvas.getContext("2d");
        let userMsg = information();
        let Time = new Date();
        let img = new Image();
        let img1 = new Image();
        let img2 = new Image();
        // let imgId = document.querySelector("img");
        img.src = that.bgImg;
        img1.src = that.userUrl;
        img2.src = that.qrcode;
        img1.setAttribute("crossOrigin", "Anonymous");
        img2.setAttribute("crossOrigin", "Anonymous");
        var imgNum = 0;
        img.onload = function () {
          imgNum += 50;
          if (imgNum === 150) {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            ctx.font = "12px 'WenQuanYi Micro Hei'";
            ctx.fillStyle = "#1D2B58";
            ctx.fillText(userMsg.nickname, 160, 135);
            ctx.fillText(userMsg.idCardCode, 175, 164);
            // ctx.fillText("ces", 175, 164);
            // ctx.fillText(userMsg.companyName, 175, 294,120);
            that.wrapText(ctx, userMsg.companyName, 175, 194, 130, 15);
            ctx.font = "18px 'WenQuanYi Micro Hei'";
            ctx.fillText(Time.getFullYear(), 50, 258);
            ctx.fillText(Time.getMonth() + 1, 119, 258);
            ctx.fillText(Time.getDate(), 160, 258);
            ctx.drawImage(img2, 130, 315, 70, 70);
            ctx.drawImage(img1, 28, 100, 87, 130);
            that.diplomaUrl = canvas.toDataURL("image/png");
            if (type) {
              that.saveDip();
            } else {
              Toast.clear();
            }
          }
        };
        img1.onload = function () {
          imgNum += 50;
          if (imgNum === 150) {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            ctx.font = "12px 'WenQuanYi Micro Hei'";
            ctx.fillStyle = "#1D2B58";
            ctx.fillText(userMsg.nickname, 160, 135);
            ctx.fillText(userMsg.idCardCode, 175, 164);
            // ctx.fillText("ces", 175, 164);
            // ctx.fillText(userMsg.companyName, 175, 194,120);
            that.wrapText(ctx, userMsg.companyName, 175, 194, 130, 15);
            ctx.font = "18px 'WenQuanYi Micro Hei'";
            ctx.fillText(Time.getFullYear(), 50, 258);
            ctx.fillText(Time.getMonth() + 1, 119, 258);
            ctx.fillText(Time.getDate(), 160, 258);
            ctx.drawImage(img2, 130, 315, 70, 70);
            ctx.drawImage(img1, 28, 100, 87, 130);
            that.diplomaUrl = canvas.toDataURL("image/png");
            if (type) {
              that.saveDip();
            } else {
              Toast.clear();
            }
          }
        };
        img2.onload = function () {
          imgNum += 50;
          if (imgNum === 150) {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            ctx.font = "12px 'WenQuanYi Micro Hei'";
            ctx.fillStyle = "#1D2B58";
            ctx.fillText(userMsg.nickname, 160, 135);
            ctx.fillText(userMsg.idCardCode, 175, 164);
            // ctx.fillText("ces", 175, 164);
            // ctx.fillText(userMsg.companyName, 175, 194,120);
            that.wrapText(ctx, userMsg.companyName, 175, 194, 130, 15);
            ctx.font = "18px 'WenQuanYi Micro Hei'";
            ctx.fillText(Time.getFullYear(), 50, 258);
            ctx.fillText(Time.getMonth() + 1, 119, 258);
            ctx.fillText(Time.getDate(), 160, 258);
            ctx.drawImage(img2, 130, 315, 70, 70);
            ctx.drawImage(img1, 28, 100, 87, 130);
            that.diplomaUrl = canvas.toDataURL("image/png");
            if (type) {
              that.saveDip();
            } else {
              Toast.clear();
            }
          }
          // that.diplomaUrl = canvas.toDataURL("image/png");
        };
      });
      // img.src = "../../assets/img/bg.png";
    },
    saveDip() {
      updateDiploma({
        // pid: this.pid,
        openId: getOpenid(),
        diploma: this.diplomaUrl,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$toast("保存成功");
          this.isRemake = false;
          Toast.clear();
        }
      });
    },
    //文件上传
    afterRead(itm) {
      return (file) => {
        this.$toast.loading({ duration: 0 });
        let formData = new FormData();
        let data = file.file;
        formData.append("files", data);
        File(formData)
          .then((res) => {
            if (res.data.code == 200) {
              this.newUrl = res.data.data;
              this.drawdiploma(false, res.data.data);
              this.$toast("上传成功");
            } else {
              this.$toast("上传失败");
            }
          })
          .catch((err) => {
            this.$toast("上传失败");
          });
      };
    },
    //重拍
    remake() {
      this.hasdiploma = false;
      this.fileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.isExcepot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.noExcepot {
  text-align: center;
  padding-top: 35px;
  font-size: 12px;
  color: #828282;
}
.hasdiploma {
  padding-bottom: 65px;
  img {
    width: 100%;
  }
}
/deep/.van-nav-bar__content {
  background: linear-gradient(89deg, #f2f3fb 0%, #dae8ff 46%, #dafffb 100%);
}
.person {
  background: linear-gradient(89deg, #f2f3fb 0%, #dae8ff 46%, #dafffb 100%);
}
.header {
  width: 100%;
  padding: 0 24px 0px 24px;
  height: 130px;
  background: linear-gradient(89deg, #f2f3fb 0%, #dae8ff 46%, #dafffb 100%);
  padding-top: 50px;
  box-sizing: border-box;
  .introduce {
    width: 100%;
    height: 80px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background-color: #f2f3fb;
      margin-left: 11px;
    }
    .title {
      margin-left: 12px;
      .name {
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }
      .company {
        font-size: 12px;
        color: #828282;
        margin-top: 4px;
      }
    }
  }
}
.information {
  padding: 18px 24px 0px 24px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;
  padding-bottom: 30px;
  background: #ffffff;
  margin-top: 20px;
  .van-tabs {
    width: 100%;
  }
  .informationitem {
    padding: 15px 12px;
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    p {
      font-size: 16px;
      text-align: center;
      color: #4386f5;
      background-color: #f2f3fb;
      line-height: 40px;
      a {
        color: #4386f5;
      }
    }
  }
  .iphone {
    //padding: 10px 12px;
    margin-top: 20px;
    box-sizing: border-box;
    width: 100%;
    p {
      font-size: 16px;
      text-align: center;
      color: #4386f5;
      background-color: #f2f3fb;
      line-height: 47px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      a {
        color: #4386f5;
      }
      span {
        //opacity:70%;
      }
    }
  }
  .from {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fotab {
      width: 80px;
      color: #828282;
    }
    .tab {
      color: #333333;
      font-size: 14px;
      font-weight: 500;
    }
  }

  /deep/.van-tabs__line {
    background-color: rgba(0, 0, 0, 0);
  }
  /deep/.van-tab {
    font-size: 16px;
    padding-bottom: 15px;
  }
}
.acat {
  width: 240px;
  height: 300px;
  background-color: #f2f3fb;
  border: 1px solid #b5d1ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  .van-button--primary {
    border: none;
    background-color: #f2f3fb;
    .van-icon {
      color: #b5d1ff;
      font-size: 40px;
    }
  }
  .upload {
    color: #b5d1ff;
    margin-top: 15px;
    font-weight: 500;
    font-size: 12px;
  }
}
/deep/.van-uploader__preview-image {
  width: 240px !important;
  height: 300px !important;
  margin-left: 40px;
}
.certificate {
  text-align: center;
  color: #828282;
  font-size: 12px;
  margin-top: 20px;
}
.telphoneCall {
  position: fixed;
  bottom: 90px;
  right: 10px;
  background: #4386f5;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  text-align: center;
  font-size: 0;
  img {
    width: 24px;
    height: 24px;
    margin-top: 12px;
  }
  p {
    font-size: 12px;
    color: #fff;
  }
}
</style>
