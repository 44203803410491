import axios from "axios";
// import { MessageBox, Message } from "element-ui";
// import store from "@/store";
// import { getToken } from "@/utils/auth";
// import router from "@/router";

// create an axios instance
export const request = axios.create({
  // baseURL: "https://signin.sccqhome.cn", // url = base url + request url
  baseURL: "",
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});
// request interceptor
request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    console.log(config);
    const token = sessionStorage.getItem("token");
    if (token && token != null) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
request.interceptors.response
  .use
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  // (response) => {
  //   return response;
  // },
  // (error) => {
  //   var res = error.response;
  //   if (res) {
  //     switch (res.status) {
  //       case 302:
  //         Message({
  //           message: "登录超时，请重新登录",
  //           type: "error",
  //         });
  //         router.push({ path: "/login" });
  //         break;
  //       case 401:
  //         router.push({ path: "/login" });
  //         break;
  //       case 500:
  //         Message({
  //           message: "服务器异常",
  //           type: "error",
  //         });
  //         break;
  //       case 403:
  //         Message({
  //           message: "您没有权限访问",
  //           type: "error",
  //           showClose: true,
  //           duration: 5 * 1000,
  //         });
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  //   return Promise.reject(error);
  // }
  ();

export const post = (url, params) => {
  return request({
    method: "post",
    url: url,
    data: params,
  });
};
export const put = (url, params) => {
  return request({
    method: "put",
    url: url,
    data: params,
  });
};
export const get = (url, params) => {
  return request({
    method: "get",
    url: url,
    params: params,
  });
};
export const del = (url, params) => {
  return request({
    method: "delete",
    url: url,
    params: params,
  });
};

export const filedown = (url, params) => {
  return request({
    responseType: "blob",
    method: "get",
    url: url,
    params: params,
    headers: {
      "Content-Type": "application/pdf",
    },
  });
};
export default request;
