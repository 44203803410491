<template>
  <div style="height: 100%">
    <!--<van-nav-bar
      title=""
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />-->
    <!---头部--->
    <div class="header">
      <img class="banner" :src="imgUrl" alt="" />
    </div>
    <!--信息栏-->
    <div style="height: calc(100% - 205px); margin-bottom: 50px">
      <div
        class="information"
        v-for="videoItem in dataVideosList"
        :key="videoItem.type"
      >
        <!--标题-->
        <div class="text" v-if="videoItem.List.length > 0">
          {{
            videoItem.type == 1
              ? "《四川省物业管理条例》条文解读"
              : "物业服务法律实务解析"
          }}
        </div>
        <!--视频-->
        <div
          style="display: flex; flex-wrap: wrap; justify-content: space-between"
        >
          <video-item
            ref="videoitem"
            style="width: calc(50% - 10px)"
            :video-item="item"
            class="video"
            v-for="(item, index) in videoItem.List"
            :key="videoItem.id"
            @handleAudit="handleAudit"
          ></video-item>
        </div>
      </div>
    </div>
    <!-- 弹出框 审核通过可以查看视频，没有通过不可以查看弹框 -->
    <van-overlay :show="isPass">
      <div class="notwatch" @click.stop>
        <div class="block">
          <img src="../../assets/img/notwatch.png" alt="" />
          <div class="success">
            <div class="succ">无法观看，请联系工作人员</div>
            <div class="sec">咨询电话：028-85353498</div>
          </div>
          <van-button type="info" @click="isPass = false">好的</van-button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Dialog, Notify, Toast } from "vant";
import videoItem from "./videoitem";
import { getVideoList, getVideoByList, Login, getOpenId } from "@/api/login";

export default {
  name: "video",
  components: {
    videoItem,
  },
  data() {
    return {
      imgUrl: require("../../assets/img/banner.jpg"),
      imgdata1: [
        {
          title: "条文解读(上)",
          poster: require("../../assets/img/rules_top.jpg"),
        },
        {
          title: "条文解读(中)",
          poster: require("../../assets/img/rules_center.jpg"),
        },
        {
          title: "条文解读(下)",
          poster: require("../../assets/img/rules_bottom.jpg"),
        },
        {
          title: "01-业主",
          poster: require("../../assets/img/property.jpg"),
        },
        {
          title: "02-物业服务人",
          poster: require("../../assets/img/property.jpg"),
        },
        {
          title: "03-筹备组、业主大会、业主委员会",
          poster: require("../../assets/img/property.jpg"),
        },
        {
          title: "04-街道办事处、居民委员会",
          poster: require("../../assets/img/property.jpg"),
        },
      ], //视频poster图片
      dataVideosList: [], //视频列表信息
      isPass: false, //true没通过，false通过
      information: {}, //用户信息
      login: { openId: "o71E55nzfvY6l-7An9LMr8cVTwiI" },
    };
  },
  created() {
    this.information = JSON.parse(sessionStorage.getItem("msg"));
  },
  mounted() {
    /***
     * 本地测试直接调登录方法
     */
    // this.loginFnc();
    /**
     * 线上测试用下面代码
     */
    let openId = sessionStorage.getItem("openId");
    this.login.openId = openId;
    if (openId) {
      this.loginFnc();
    } else {
      this.getUserCode();
    }
  },
  methods: {
    //login
    loginFnc() {
      Login(this.login)
        .then((res) => {
          sessionStorage.setItem("openId", this.login.openId);
          if (res.data.msg == "NoRegister") {
            Dialog.alert({
              message: "当前账号未注册信息，即将前往注册",
            }).then(() => {
              // on close
              this.$router.push({
                path: "/login",
              });
            });
          } else {
            if (res.data.code == 200) {
              sessionStorage.setItem("token", res.data.data.Authorization);
              this.information = res.data.data.principal;
              let obj = JSON.stringify(this.information);
              sessionStorage.setItem("msg", obj);
              this.getVideoList();
            }
          }
        })
        .catch((err) => {
          Notify({ type: "danger", message: "服务器异常，登录失败" });
        });
    },
    getUserCode() {
      // const AppId = "wxb233756efac0b769";
      var url = location.href;
      let start = location.href.indexOf("=");
      let end = location.href.indexOf("&");
      let code = url.substring(start + 1, end);
      this.getOpenidFnc(code);
    },
    //获取openid
    getOpenidFnc(code) {
      getOpenId(code).then((res) => {
        if (res.data.code == 200) {
          this.login.openId = res.data.data.openid;
          sessionStorage.setItem("openId", res.data.data.openid);
          this.loginFnc();
        } else {
          Notify({ type: "danger", message: "获取用户id失败" });
        }
      });
    },
    handleAudit(params) {
      this.isPass = params;
    },
    //处理视频列表信息
    arrayClassFn(arr) {
      let dataArr = [];
      arr.map((mapItem) => {
        if (dataArr.length == 0) {
          dataArr.push({ type: mapItem.type, List: [mapItem] });
        } else {
          let res = dataArr.some((item) => {
            if (item.type == mapItem.type) {
              item.List.push(mapItem);
              return true;
            }
          });
          if (!res) {
            //如果没找相同日期添加一个新对象
            dataArr.push({ type: mapItem.type, List: [mapItem] });
          }
        }
      });
      return dataArr;
    },
    //获取视频列表
    getVideoList() {
      let request;
      if (this.information.status == 2) {
        //审核通过2
        request = getVideoByList({ openId: this.login.openId });
      } else {
        //审核没通过1
        request = getVideoList({ openId: this.login.openId });
      }
      request.then((res) => {
        if (res.data.code == 200) {
          this.dataVideosList = this.arrayClassFn(res.data.data);
        }
      });
    },
    onClickLeft() {
      window.history.back();
    },
    onClickRight() {
      Toast("按钮");
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.header {
  width: 100%;
  height: 205px;
  background: linear-gradient(
    180deg,
    rgba(67, 134, 245, 0.36) 0%,
    rgba(67, 134, 245, 0) 100%
  );
  border-radius: 0px 0px 0px 0px;

  .banner {
    padding: 20px;
    width: calc(100% - 40px);
  }
}
.information {
  padding: 10px 20px 20px 20px;
  .text {
    height: 24px;
    width: 300px;
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1b1b1b;
    line-height: 24px;
    -webkit-background-clip: text;
  }
  .video {
    margin-top: 20px;
    width: 100%;
  }
}
.notwatch {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .block {
    width: 260px;
    height: 300px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    text-align: center;

    img {
      width: 260px;
      height: 170px;
    }

    button {
      margin-top: 22px;
      width: 120px;
      height: 44px;
      border-radius: 72px;
      font-size: 16px;
      font-weight: 500;
    }

    .success {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .succ {
        width: 192px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 19px;
        -webkit-background-clip: text;
      }

      .sec {
        width: 149px;
        height: 12px;
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #4386f5;
        line-height: 12px;
        -webkit-background-clip: text;
        margin-top: 8px;
      }
    }
  }
}
</style>
