<template>
  <div>
    <router-view></router-view>

    <van-tabbar v-model="active"
                route>
      <van-tabbar-item icon="scan"
                       name="clock"
                       to="/home">签到</van-tabbar-item>
      <van-tabbar-item icon="video-o"
                       name="video-o"
                       to="/video">视频</van-tabbar-item>
      <van-tabbar-item to="/person"
                       name="person"
                       icon="contact">个人</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 'clock',
    };
  },
}
</script>

<style lang="less" scoped>
</style>
