//获取openid
export const getOpenid = (openid = "o71E55oKKccRcsgcBxSDhZe6-zEA") => {
    return sessionStorage.getItem("openId")
        ? sessionStorage.getItem("openId")
        : openid;
}

//获取用户信息
export const information = ()=>{
    return JSON.parse(sessionStorage.getItem("msg"))
        ? JSON.parse(sessionStorage.getItem("msg"))
        : {};
}
