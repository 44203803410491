<template>
  <div style="height:100%;width: 100%">
    <!--返回按钮-->
    <van-nav-bar
      style="height: 46px;position:relative;left: 0;top: 0;"
      :title="details.title"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <!--视频-->
    <video
        class="video"
        preload="meta"
        :src="details.videoPath"
        ref="video"
        autoplay
        :webkit-playsinline="playInline"
        :playsinline="playInline"
        @play="handleFullScreen"
        @pause="playPause"
        style="object-fit: fill;width: 100%;"
        controls
        disablePictureInPicture
        controlslist="nodownload"
    ></video>
  </div>
</template>

<script>
import {Toast} from "vant";
import {videoComplete, getVideoByList} from "@/api/login";
import {getOpenid} from "@/utils/util";
import { getSeconds,getVehicle} from '@/utils/date'

export default {
  name: "myvideo",
  components: {},
  data() {
    return {
      details: {}, //获取路由传参数据
      currTime: null, //当前时间
      maxTime: 0,
      video: null,
      //是否全屏播放
      playInline: false,
      timer:null,
    };
  },
  computed:{},
  created() {},
  mounted() {
    let that = this;
    that.details = this.$route.query;
    that.video = this.$refs.video;
    //每个视频的isEnded为true时，视频从0开始播放，否则从上次观看时间开始播放，1是已完成，2是未完成
    var num = that.details.status == 1 ? 0 : getSeconds(that.details.playTime);
    that.playBySeconds(num);
    //判断未看完状态不能拖动，已看状态可以拖动
    if (that.details && that.details.status == 2) {
      that.video.addEventListener("timeupdate", that.timeupdate);
    }
    //监听全屏
    this.$nextTick(() => {
      this.handleFullScreen(this.video);
    });
    //监听播放完成
    that.video.addEventListener("ended", that.ended);
    //ios绑定全屏事件
    this.video.addEventListener("webkitbeginfullscreen",()=>{
      this.playInline = false
    })
    this.video.addEventListener("webkitendfullscreen",()=>{
      this.playInline = true
    })
    //安卓绑定全屏事件
    this.video.addEventListener("x5videoenterfullscreen",()=>{
      this.playInline = false
    })
    this.video.addEventListener("x5videoexitfullscreen",()=>{
      this.playInline = true
    })
    //ios监听画中画
    this.video.addEventListener("enterpictureinpicture",()=>{
      setTimeout(() => {
        // 退出画中画
        document.exitPictureInPicture()
      }, 100);
    })
    //定时上报观看时间：微信X(关闭)按钮，退出后记录不了观看时间
    this.timer = setInterval(() => {
      if (this.details.status == 2) {
        this.videoComplete();
      }
    },10000)
  },
  methods: {
    onClickLeft() {
      if (this.details.status == 2) {
        this.videoComplete();
      }
      window.history.back();
    },
    onClickRight() {
      Toast("按钮");
    },
    /*
     * 全屏
     */
    handleFullScreen(e) {
      this.launchFullscreen(e);
    },
    /*
     * 全屏
     */
    launchFullscreen(element) {
      if (element.requestFullscreen) {
        // w3c
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        // firefox
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        // webkit
        element.webkitRequestFullScreen();
      }
    },
    /*
     * 退出全屏
     */
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    /*
     * 观看视频完成
     */
    videoComplete() {
      let query = {
        id: this.details.id,
        openId: getOpenid(),
        playTime: getVehicle(this.currTime),
        // playTime: '00:01:00',
      };
      return videoComplete(query);
    },
    /*
     * 二次播放方法
     */
    playBySeconds(num) {
      if (num && this.video) {
        this.video.currentTime = num;
        this.currTime = num;
        this.video.play();
      }
    },
    /*
     * 禁止拖动，快进
     */
    timeupdate(e) {
      if (e.srcElement.currentTime - this.currTime > 1) {
        e.srcElement.currentTime =
            this.currTime > this.maxTime ? this.currTime : this.maxTime;
        this.$toast("禁止快进");
      }
      this.currTime = e.srcElement.currentTime;
      this.maxTime = this.currTime > this.maxTime ? this.currTime : this.maxTime;
    },
    /*
     * 禁止暂停
     */
    playPause() {
      if (!this.video) {
        return;
      }
      //当前观看时间<视频总时长 && 保证未看完状态不能暂停，已看状态可以暂停的操作
      if (this.details.status == 2){
        if (
            this.currTime < this.video.duration &&
            this.details
        ) {
          this.$toast("禁止暂停");
          this.$nextTick(() => {
            setTimeout(() => {
              this.video && this.video.play();
            }, 300);
          });
        }else{
          this.videoComplete();
        }
      }
    },
    /*
     * 播放完成
     */
    ended() {
      if (!this.video) {
        return;
      }
      if (this.currTime < this.video.duration) {
        return;
      }
      if (this.details.status == 2) {
        this.videoComplete().then((res) => {
          getVideoByList({openId: getOpenid()}).then((resp) => {
            //是否所有视频全部学习完成
            if (resp.data.data.every(item => item.status == 1)) {
              this.$router.push({path: "/person", query: {active: "b"}});
            } else {
              this.$router.go(-1);
            }
            this.video.removeEventListener("timeupdate", this.timeupdate);
          });
        });
      } else {
        this.video.removeEventListener("timeupdate", this.timeupdate);
      }
    },
  },
  beforeDestroy() {
    if (this.video) {
      //状态为已看后就禁止修改状态，未完成(2),已完成(1);
      if (this.details.status == 2) {
        this.videoComplete();
      }
      this.video.removeEventListener("timeupdate", this.timeupdate);
      this.video = null;
    }
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
};
</script>

<style lang="less" scoped>
.video{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}
</style>
