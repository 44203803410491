<template>
  <div>
    <div class="videocontainer" @click="handlePlay(videoItem)">
      <!---视频播放器--->
      <video ref="video"
             class="myvideo"
             preload="auto"
             :src="videoItem.videoPath"
             :poster="videoItem.imgCover"
      ></video>
      <!---视频总时长--->
      <div class="videoitem">
          <span class="icontime">
            <van-icon name="play"/>
            <span>{{videoItem.totalTime}}</span>
          </span>
      </div>
      <!---视频观看状态--->
      <div v-if="videoItem.status == 1" class="havesee">
        <img :src="haveseeUrl" alt="">
        <span>已看</span>
      </div>
    </div>
    <!---视频标题--->
    <div class="videotitle">{{videoItem.title}}</div>
  </div>
</template>

<script>
import {information} from "@/utils/util";

export default {
  name: "videoitem",
  props: {
    videoItem: {
      type: Object,
    },
  },
  data() {
    return {
      video: {},
      haveseeUrl:require("../../assets/img/havesee.png"),
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.video = this.$refs.video
      //this.$parent.getVideoList()
    })
  },
  methods: {
    /*
     * 根据用户信息状态来区分下一步操作
     * 1审核未通过:提示弹窗，2审核通过:观看视频
     */
    handlePlay(v) {
      let isPass = information().status == 1 ? true : false;
      this.$emit("handleAudit", isPass);
      if (!isPass){
        this.$router.push({path: '/myvideo', query: v})
      }
    },
  }
}
</script>

<style lang="less" scoped>
.videocontainer {
  position: relative;
  bottom: 0;
  left: 0px;
  height: 95px;
  margin-bottom: 10px;

  .myvideo {
    width: 100%;
    height: 95px;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    object-fit: fill;
  }

  .videoitem {
    position: relative;
    bottom: 34px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 30px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    border-radius: 0px 0px 6px 6px;
    opacity: 1;
    color: #FFFFFF;

    .icontime {
      position: absolute;
      bottom: 6px;
      right: 10px;
      height: 18px;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 18px;
      -webkit-background-clip: text;
    }
  }

  .havesee {
    position: absolute;
    top: -4px;
    right: 10px;

    img {
      width: 38px;
      height: 28px;
    }

    span {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 18px;
      position: absolute;
      top: 6px;
      right: 9%;
      margin: 0 auto;
      width: 25px;
      height: 18px;
      -webkit-background-clip: text;
    }
  }
}
.videotitle {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 21px;
  -webkit-background-clip: text;
}
</style>
