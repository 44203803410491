import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';

import Vant from 'vant';
Vue.use(Vant);

//二维码
import VueBarcode from 'vue-barcode';
Vue.component('barcode', VueBarcode)

import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)

// import * as vConsole from 'vconsole'
// new vConsole()

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
